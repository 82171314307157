angular.module('fingerink')
    .service('selectSendTypeModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'signaturemodal',
                keyboard: false,
                controller: 'selectSendTypeModalCtrl',
                templateUrl: 'modals/selectSendTypeModal.tpl.html'
            });
            return this.modalInstance;
        };
    })
    .controller('selectSendTypeModalCtrl', function mostrarCtrl($rootScope, $scope, $uibModalInstance) {
        $scope.ok = function (s) {
            $uibModalInstance.close(s);
        };
    });
